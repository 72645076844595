<template>
  <div class="relative flex flex-col h-full">
    <div class="flex m-5 mb-0 flex-shrink-0">
      <div class="flex-1">
        <div class="text-lg font-bold">{{ title }}</div>
        <div class="text-sm">{{ instructions }}</div>
      </div>
    </div>

    <slot name="previous"></slot>
    <div v-if="allowUpload || searchable" class="flex mx-5 mt-3 gap-4 flex-shrink-0">
      <div v-if="searchable" class="relative mb-0 flex-1">
        <fw-icon-search class="text-gray-400 absolute top-3 left-3 h-5 w-5"></fw-icon-search>
        <input
          v-model="searchQuery"
          class="w-full border border-gray-100 shadow px-2 py-2 pl-10 outline-none active:ring-2 focus:ring-2 ring-primary ring-opacity-30 rounded-md"
          type="text"
          placeholder="Pesquisar..."
        />
      </div>
      <Uploader
        :label="'Carregar ficheiros'"
        :is-docked="true"
        :layout="'simple'"
        :reference-id="'final_notification_uploader'"
        :allowed="'all'"
        :clear-after="true"
        :simple-uploader-type="'primary'"
        input-id="upload_input"
        :files.sync="updateFilesToUpload"
        :size="0"
        :new-file-context="newFileContext"
        :file-type="'file'"
        :file-code="''"
        class="cursor-pointer flex-shrink-0"
        :uploader-class="'w-full h-full rounded'"
        @upload="uploaded($event)"
      />
    </div>
    <LoadingPlaceholder v-if="loading" class="flex-shrink-0 m-5" :list="true"></LoadingPlaceholder>
    <div v-else class="flex-1 py-4" :style="{ height: maxHeight + 'px' }">
      <RecycleScroller
        v-if="filteredFiles.length"
        v-slot="{ item, index }"
        :items="filteredFiles"
        :item-size="55"
        :buffer="200"
        :key-field="idKey"
        class="h-full"
      >
        <SelectableItem :checked="item.selected" class="px-5" @selected="selectFile(index, $event)">
          <RecordFileEntry
            :key="index"
            class="pl-2"
            :file="item"
            :paddingless="true"
            :can-remove="canDeleteFiles"
            @download="downloadFile(item)"
          >
            <template #secondline
              ><div v-if="item.context" class="text-gray-500 text-xs">em {{ item.context.title }}</div></template
            >
          </RecordFileEntry>
        </SelectableItem>
      </RecycleScroller>
      <div v-else class="text-center text-lg text-gray-500 py-10">
        {{ searchQuery.length ? 'Sem resultados' : 'Sem ficheiros' }}
      </div>
    </div>
    <div
      v-if="multiselect || showConfirmation"
      class="flex bg-gray-100 border-t border-gray-200 p-5 gap-5 flex-shrink-0"
    >
      <button
        class="text-center leading-5 font-semibold flex bg-opacity-90 px-2 py-3 w-full items-center rounded-lg justify-center shadow text-gray-800 text-opacity-90 hover:text-opacity-100 hover:shadow-lg bg-gray-50 border border-gray-100"
        @click="closeModal"
      >
        {{ cancelButtonText }}
      </button>

      <button
        class="text-center leading-5 font-semibold flex bg-opacity-90 px-2 py-3 w-full items-center rounded-lg justify-center shadow text-opacity-90"
        :class="{
          'hover:text-opacity-100 hover:shadow-lg bg-primary text-white': haveSelectedFiles,
          'bg-gray-100 cursor-not-allowed text-gray-500': confirmDisabled || !haveSelectedFiles
        }"
        @click="saveSelection"
      >
        {{ confirmButtonText }}
      </button>
    </div>
  </div>
</template>

<script>
import Uploader from '@/fw-modules/fw-core-vue/storage/components/PathUploader.vue'
import { RecycleScroller } from 'vue-virtual-scroller'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import SelectableItem from '../form/SelectableItem'
import RecordFileEntry from '../form/RecordFileEntry'
import Vue from 'vue'
export default {
  name: 'ChooseFilesModal',
  components: { LoadingPlaceholder, SelectableItem, RecordFileEntry, RecycleScroller, Uploader },
  props: {
    idKey: {
      type: String,
      default: 'key'
    },
    showModal: {
      default: false,
      type: Boolean
    },
    multiselect: {
      default: true,
      type: Boolean
    },
    searchable: {
      default: true,
      type: Boolean
    },
    invitePeople: {
      default: false,
      type: Boolean
    },
    title: {
      type: String,
      default: 'Anexar ficheiro(s)'
    },
    instructions: {
      type: String,
      default: 'Seleccione o(s) ficheiro(s) que pretende'
    },
    selected: {
      //selected keys
      type: Array,
      default: function() {
        return []
      }
    },
    showConfirmation: {
      type: Boolean,
      default: true
    },
    cancelButtonText: {
      type: String,
      default: 'Cancelar'
    },
    confirmButtonText: {
      type: String,
      default: 'Guardar seleção'
    },
    autoClose: {
      type: Boolean,
      default: true
    },
    confirmDisabled: {
      type: Boolean,
      default: false
    },
    selectionTypes: {
      type: Array,
      default: function() {
        return []
      }
    },
    currentSelectionTypeIndex: {
      type: Number,
      default: 0
    },
    files: {
      type: Array,
      default: function() {
        return []
      }
    },
    loading: {
      type: Boolean,
      default: true
    },
    allowUpload: {
      type: Boolean,
      default: false
    },
    canDeleteFiles: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchQuery: '',
      selectedList: [],
      tmp_files: [],
      updateFilesToUpload: []
    }
  },
  computed: {
    newFileContext() {
      return {}
    },
    filteredFiles() {
      if (this.searchQuery.length) {
        return this.tmp_files.filter(file => file.filename.toLowerCase().includes(this.searchQuery.toLowerCase()))
      }
      return this.tmp_files
    },
    maxHeight() {
      return 500
    },
    haveSelectedFiles() {
      return this.tmp_files.filter(file => file.selected).length > 0
    }
  },
  watch: {
    files() {
      this.processfiles()
    }
  },
  created() {
    //load users
    //this.loading = false
    this.processfiles()
  },
  methods: {
    processfiles() {
      let files = JSON.parse(JSON.stringify(this.files))
      for (let i = 0; i < files.length; i++) {
        if (this.selected.includes(files[i].key)) {
          files[i].selected = true
        } else {
          files[i].selected = false
        }
      }
      this.tmp_files = files
    },
    selectFile(f, event) {
      Vue.set(this.tmp_files[f], 'selected', event)
    },
    async downloadFile(file) {
      console.log('downloadFile', file)
      let url

      //const response = await ServiceStorage.getFiles([file.key])
      //console.log('DOWNLOAD FILE', response)
      //url = this.getFileViewUrl(response)

      let userToken = this.$store.state.session.user.token
      let fileurl = file.file ? file.file.url_format : file.url_format
      let filekey = file.file ? file.file.key : file.key
      let filename = file.file && file.file.filename ? file.file.filename : file.filename
      console.log('userToken', userToken, 'URL', fileurl)
      url = fileurl
        .replaceAll('{TOKEN}', userToken)
        .replaceAll('{KEY}', filekey)
        .replaceAll('{FILENAME}', filename)

      //const response = await this.api.getFile(file.key, file.filename)
      //url = window.URL.createObjectURL(new Blob([response]))
      /*if (file.url) {
        url = file.url
        url = window.URL.createObjectURL(new Blob([response]))
      } else if (file.url_format) {
        url = this.getFileViewUrl(file)
      } else {
        //const response = await this.api.getFile(file.key, file.filename)
        //url = window.URL.createObjectURL(new Blob([response]))
        const response = await ServiceStorage.getFiles([file.key])
        console.log('DOWNLOAD FILE', response)
        url = this.getFileViewUrl(response)
      }*/

      const link = document.createElement('a')
      link.href = url
      link.target = '_blank'
      link.setAttribute('download', file.filename)
      document.body.appendChild(link)
      link.click()
    },

    closeModal() {
      this.$emit('close')
    },
    saveSelection() {
      if (this.$listeners && this.$listeners.confirm) {
        this.$emit(
          'confirm',
          this.tmp_files.filter(file => file.selected)
        )
      } else {
        this.$emit(
          'selected',
          this.tmp_files.filter(file => file.selected)
        )
        this.$emit('confirm')
        this.$emit('close')
      }
    },
    uploaded(event) {
      this.$emit('upload', event)
    }
  }
}
</script>

<style scoped></style>
