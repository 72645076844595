<template>
  <div class="fw-person fw-row-checkbox">
    <label class="relative select-none fw-row-checkbox-selectable">
      <input v-model="currentValue" type="checkbox" class="opacity-0 absolute" @change="selectItem" />
      <div
        class="fw-row-checkbox-inside flex w-full gap-1 items-center relative justify-between group"
        :class="{
          'rounded-lg bg-opacity-10 shadow-sm': !noStyle,
          'p-2 my-1': paddingless === false
        }"
      >
        <div class="check h-4 w-4 rounded p-1"></div>

        <div class="flex-1 pl-5">
          <slot></slot>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'SelectableItem',
  props: {
    noStyle: {
      type: Boolean,
      default: false
    },
    view: {
      type: String,
      default: 'row'
    },

    checked: {
      type: Boolean,
      default: false
    },
    paddingless: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      currentValue: false
    }
  },
  computed: {
    hasSecondLineSlot() {
      return !!this.$slots['secondline']
    },
    hasOptionsSlot() {
      return !!this.$slots['options']
    }
  },
  created() {
    this.currentValue = this.checked //this.person.selected === true ? true : false
  },
  methods: {
    selectItem: function() {
      //Vue.set(this.person, 'selected', this.currentValue)
      this.$emit('selected', this.currentValue)
    }
  }
}
</script>

<style>
.fw-row-checkbox-selectable input[type='checkbox']:checked + .fw-row-checkbox-inside {
  background-color: rgba(45, 212, 191, 0.1);
}

.fw-row-checkbox-selectable:hover input[type='checkbox']:checked + .fw-row-checkbox-inside {
  background-color: rgba(45, 212, 191, 0.5);
}
.fw-row-checkbox-selectable input[type='checkbox'] + .fw-row-checkbox-inside {
  background-color: transparent;
}
.fw-row-checkbox-selectable input[type='checkbox'] + .fw-row-checkbox-inside .check {
  position: absolute;
  background: white;
  border: solid 1px #6accb3;
}
.fw-row-checkbox-selectable input[type='checkbox']:checked + .fw-row-checkbox-inside .check {
  background: #30a898
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'%3E%3Cpath style='fill:%23fff' d='M 0.04038059,0.6267767 0.14644661,0.52071068 0.42928932,0.80355339 0.3232233,0.90961941 z M 0.21715729,0.80355339 0.85355339,0.16715729 0.95961941,0.2732233 0.3232233,0.90961941 z'%3E%3C/path%3E%3C/svg%3E")
    no-repeat center center;
  border-color: #30a898;
  background-size: 80%;
}

.fw-row-checkbox-selectable input[type='checkbox']:active + .fw-row-checkbox-inside .check {
  border-color: #30a898;
}

.fw-row-checkbox-selectable input[type='checkbox']:focus + .fw-row-checkbox-inside .check {
  border-color: #30a898;
}

.fw-row-checkbox-selectable input[type='checkbox']:active + .fw-row-checkbox-inside {
  outline-style: solid;
  outline-width: 2px;
  outline-color: rgba(45, 212, 191, 0.5);
}

.fw-row-checkbox-selectable input[type='checkbox']:focus + .fw-row-checkbox-inside {
  outline-style: solid;
  outline-width: 2px;
  outline-color: rgba(45, 212, 191, 0.5);
}

.fw-row-checkbox-selectable input[type='checkbox'] + .fw-row-checkbox-inside .repo-icon {
  background-color: rgba(229, 231, 235, 0.5);
}
.fw-row-checkbox-selectable input[type='checkbox']:checked + .fw-row-checkbox-inside .repo-icon {
  background-color: white;
}
.fw-row-checkbox-selectable input[type='checkbox'] + .fw-row-checkbox-inside .id-label {
  background-color: rgb(234, 234, 234);
  color: rgb(59, 58, 58);
}
.fw-row-checkbox-selectable input[type='checkbox']:checked + .fw-row-checkbox-inside .id-label {
  background-color: #30a898;
  color: white;
}

.fw-row-checkbox .fw-row-checkbox-inside {
  background-color: white;
}
.fw-row-checkbox .fw-row-checkbox-inside .repo-icon {
  background-color: rgba(230, 232, 234, 0.5);
}
.fw-row-checkbox:hover .fw-row-checkbox-inside .repo-icon {
  background-color: #def8ef;
}

.fw-row-checkbox .fw-row-checkbox-inside .id-label {
  background-color: rgb(234, 234, 234);
  color: rgb(59, 58, 58);
}
.fw-row-checkbox .fw-row-checkbox-inside .id-label {
  background-color: #30a898;
  color: white;
}
</style>
